import { useRef } from 'react';

export function FormSelect({ customProps, formProps }: { customProps: any; formProps: any }) {
  const {
    id,
    label = '',
    options = [],
    containerClassName = '',
    labelClassName = '',
    inputClassName = '',
  } = customProps;

  const inputRef = useRef<HTMLSelectElement>();

  return (
    <div className={containerClassName} onClick={() => inputRef.current?.focus()}>
      <label htmlFor={id} className={`block text-gray-700 text-sm font-bold mb-2 ${labelClassName}`}>
        {label}
      </label>
      <div className="relative">
        <select
          ref={inputRef}
          className={`block appearance-none w-full border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:bg-white focus:border-gray-500${inputClassName}`}
          id={id}
          {...formProps}
        >
          {options.map((option: { label: string }) => (
            <option key={option.label}>{option.label}</option>
          ))}
        </select>
        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
          <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
            <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
          </svg>
        </div>
      </div>
    </div>
  );
}
