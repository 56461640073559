import { atom } from 'recoil';

export type LoadingState = boolean;

const STATE_NAMESPACE = 'app-state';

export const loadingState = atom<LoadingState>({
  key: `${STATE_NAMESPACE}/loading`,
  default: false,
});

type ExercisesState = {
  id: string;
  name: string;
};

export const exercisesState = atom<ExercisesState[]>({
  key: `${STATE_NAMESPACE}/exercises`,
  default: [],
});
