import {
  getAuth,
  GoogleAuthProvider,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signInWithPopup,
  signOut as signOutOfFirebase,
} from 'firebase/auth';

import { firebaseAuth } from './firebase-connection';

import type { UserCredential } from 'firebase/auth';

export type User = {
  userId: string;
  email?: string;
};

export const getCurrentUserId = (): string | null => {
  const { currentUser } = getAuth();

  if (!currentUser) return null;

  return currentUser.uid;
};

export const signIn = async (email: string, password: string): Promise<UserCredential> =>
  signInWithEmailAndPassword(firebaseAuth, email, password);

export const signInWithGoogle = async (): Promise<UserCredential> => {
  const provider = new GoogleAuthProvider();

  return signInWithPopup(firebaseAuth, provider);
};

export const signOut = async (): Promise<void> => {
  await signOutOfFirebase(firebaseAuth);
};

export const isUserSignedIn = (): boolean => getAuth().currentUser !== null;

export const resetPassword = async (email: string): Promise<void> => {
  await sendPasswordResetEmail(firebaseAuth, email, {
    url: 'https://www.gym-tracker.de',
  });
};
