import { doc, getDoc, setDoc, updateDoc } from '@firebase/firestore';
import { sendEmailVerification } from 'firebase/auth';

import { getCurrentUserId } from '../firebase-auth';
import { firebaseStore } from '../firebase-connection';

import type { User } from '@firebase/auth';

export type UserDocument = {
  id: string;
  email: string;
  fistName: string;
  lastName: string;
  birthday: string;
  language: string;
  lastExercises: string[];
};

export async function createUser(user: User) {
  const userId = getCurrentUserId();

  if (userId) {
    await sendEmailVerification(user, {
      url: `https://gym-tracker.de/finish-signup/${userId}`,
    });
    await setDoc(doc(firebaseStore, 'users', user.uid), {
      id: user.uid,
      email: user.email ?? '',
      fistName: '',
      lastName: '',
      birthday: '',
      language: 'de',
      lastExercises: [],
      isVerified: user.emailVerified,
    });
  }
}

export async function getCurrentUser(): Promise<UserDocument | null> {
  const userId = getCurrentUserId();

  if (userId) {
    const userDoc = await getDoc(doc(firebaseStore, 'users', userId));

    if (userDoc.exists()) {
      return userDoc.data() as UserDocument;
    }
  }

  return null;
}

export async function updateCurrentUser(updatedUser: Partial<UserDocument>) {
  const userId = getCurrentUserId();

  if (userId) {
    await updateDoc(doc(firebaseStore, 'users', userId), updatedUser);
  }
}
