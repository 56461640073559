import { onAuthStateChanged } from '@firebase/auth';
import { atom } from 'recoil';
import { recoilPersist } from 'recoil-persist';

import { firebaseAuth } from '../firebase';

import type { UserDocument } from '../firebase';
import type { AtomEffect } from 'recoil';

export type UserState = UserDocument;
export type IsLoggedInState = boolean;

export const DEFAULT_USER_STATE = {
  email: '',
  id: '',
  birthday: '',
  lastExercises: [],
  fistName: '',
  lastName: '',
  language: 'de',
};

const { persistAtom } = recoilPersist();

const syncAuthEffect: AtomEffect<boolean> = ({ setSelf }) => {
  const stopListeningForAuthChanges = onAuthStateChanged(firebaseAuth, (user) => {
    setSelf(user !== null);
  });

  return () => {
    stopListeningForAuthChanges();
  };
};

export const userAuthState = atom<IsLoggedInState>({
  key: 'user-state/auth',
  default: false,
  effects_UNSTABLE: [syncAuthEffect, persistAtom],
});

export const userDataState = atom<UserState>({
  key: `user-state/data`,
  default: DEFAULT_USER_STATE,
  effects_UNSTABLE: [persistAtom],
});
