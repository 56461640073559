import { useClient } from '@hooks/client.hook';
import { createElement } from 'react';

import type { PropsWithChildren } from 'react';

export function BrowserOnlyContent({
  children,
  as = 'div',
  className = '',
}: PropsWithChildren<{ as?: string; className?: string }>) {
  const isClient = useClient();

  if (!isClient) {
    return null;
  }

  return createElement(as, { className }, children);
}
