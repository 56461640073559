export type FormSubmitButtonProps = {
  text: string;
  containerClasses?: string;
};

export function FormSubmitButton({ text, containerClasses = '' }: FormSubmitButtonProps) {
  return (
    <button
      type="submit"
      className={`mt-4 inline-block px-5 py-2 font-semibold text-white rounded-lg focus:outline-none bg-gradient-to-br from-pink-600 to-pink-700 hover:from-pink-500 hover:to-pink-700 ${containerClasses}`}
    >
      {text}
    </button>
  );
}
