import { ROUTES_PATH_CONFIG } from '@config/routes-config';
import Link from 'next/link';
import { useTranslation } from 'next-export-i18n';

const LINKS = [
  {
    translationKey: 'Impressum',
    href: '/imprint',
  },
  {
    translationKey: 'Datenschutz',
    href: '/privacy',
  },
];

export function Footer() {
  const { t } = useTranslation();

  return (
    <footer className="bg-white border-t border-gray-400 shadow">
      <div className="container max-w-screen-lg mx-auto flex py-8">
        <div className="w-full mx-auto grid gap-8 grid-cols-1 md:grid-cols-2">
          <div className="flex w-full">
            <figure className="px-4 md:px-8">
              <figcaption className="font-bold font-bold text-gray-900">{t('footer.column1Headline')}</figcaption>
              <p className="py-4 text-gray-600 text-sm">
                Mit dem <Link href={ROUTES_PATH_CONFIG.frontpage}>Gym-Tracker</Link> lassen sich Fortschritte im
                Fitnessstudio verfolgen. Dazu unterstützen Dich zahlreiche{' '}
                <Link href={ROUTES_PATH_CONFIG.tools}>Tools</Link>, wie{' '}
                <Link href={ROUTES_PATH_CONFIG.maximalkraftRechner}>Maximalkraftrechner</Link>,{' '}
                <Link href={ROUTES_PATH_CONFIG.kraftwerteRechner}>Kraftwerte-Rechner</Link> oder Kalorienrechner auf dem
                Weg zum Erfolg.
              </p>
            </figure>
          </div>

          <div>
            <figure className="px-4 md:px-8">
              <figcaption className="font-bold font-bold text-gray-900">{t('footer.column2Headline')}</figcaption>
              <ul className="list-reset items-center text-sm pt-3">
                {LINKS.map((link, index) => (
                  <li key={index}>
                    <Link
                      href={link.href}
                      className="inline-block text-gray-600 no-underline hover:text-gray-900 hover:underline py-1"
                    >
                      {link.translationKey}
                    </Link>
                  </li>
                ))}
              </ul>
            </figure>
          </div>
        </div>
      </div>
    </footer>
  );
}
