import { useRecoilState } from 'recoil';

import { loadingState } from '../state';

export function LoadingOverlay() {
  const [isLoading] = useRecoilState(loadingState);

  return (
    <>
      {isLoading && (
        <div className="fixed w-full h-full opacity-60 z-30 bg-white">
          <div className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
            <div
              style={{ borderTopColor: 'transparent' }}
              className="w-16 h-16 border-4 border-blue-400 border-solid rounded-full animate-spin"
            />
          </div>
        </div>
      )}
    </>
  );
}
