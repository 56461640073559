export const ROUTES_PATH_CONFIG = {
  frontpage: '/',
  addSet: '/add-set',
  editSet: '/edit-set',
  imprint: '/imprint',
  login: '/login',
  logout: '/logout',
  passwordReset: '/password-reset',
  passwordResetConfirm: '/password-reset-confirm',
  privacy: '/privacy',
  profile: '/profile',
  progress: '/progress',
  progressDetail: '/progress-detail',
  register: '/login',
  tools: '/tools',
  tutorials: '/tutorials',
  kraftwerteRechner: '/tools/kraftwerte-rechner',
  maximalkraftRechner: '/tools/maximalkraftrechner',
  pulsrechner: '/tools/pulsrechner',
  wiki: '/wiki',
};
