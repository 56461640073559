import clsx from 'clsx';
import DataListInput from 'react-datalist-input';

export type FormDatalistItem = {
  value: string;
  id: string;
};

export type FormDatalistProps = {
  placeholder: string;
  onSelect: (item: FormDatalistItem) => void;
  containerClassName?: string;
  label?: string;
  items?: FormDatalistItem[];
  hasErrors?: boolean;
};

export function FormDatalist(props: FormDatalistProps) {
  const { placeholder, onSelect, containerClassName = '', label = '', items = [], hasErrors = false } = props;

  // block w-full text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none border focus:border-gray-500  border-gray-200 opacity-60 bg-transparent
  return (
    <div className={containerClassName}>
      <DataListInput
        label={label}
        inputProps={{
          className: `block appearance-none w-full border text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500 ${clsx(
            {
              'border-gray-200': !hasErrors,
              'border-red-400': hasErrors,
            }
          )}`,
        }}
        labelProps={{
          className: `block text-gray-700 text-sm font-bold mb-2`,
        }}
        listboxProps={{
          className: 'bg-white p-4 max-h-60 overflow-y-scroll',
        }}
        listboxOptionProps={{
          className: 'p-2 hover:bg-gray-100',
        }}
        {...(placeholder && { placeholder })}
        items={items}
        onSelect={onSelect}
      />
    </div>
  );
}
