const alert = require('./locales/de/alert.json');
const button = require('./locales/de/button.json');
const common = require('./locales/de/common.json');
const dateFormat = require('./locales/de/date-format.json');
const error = require('./locales/de/error.json');
const exercise = require('./locales/de/exercise.json');
const footer = require('./locales/de/footer.json');
const headline = require('./locales/de/headline.json');
const modal = require('./locales/de/modal.json');
const navigation = require('./locales/de/navigation.json');
const select = require('./locales/de/select.json');
const toast = require('./locales/de/toast.json');
const word = require('./locales/de/word.json');
const metaTitle = require('./locales/de/meta-title.json');
const metaDescription = require('./locales/de/meta-description.json');
const imprint = require('./locales/de/imprint.json');
const placeholder = require('./locales/de/placeholder.json');
const label = require('./locales/de/label.json');
const maximalkraftrechner = require('./locales/de/pages/maximalkraftrechner.json');
const kraftwerteRechner = require('./locales/de/pages/kraft-rechner.json');
const pulsRechner = require('./locales/de/pages/puls-rechner.json');
const tutorials = require('./locales/de/pages/tutorials.json');
const wiki = require('./locales/de/pages/wiki.json');

const deTranslations = {
  alert,
  button,
  common,
  'date-format': dateFormat,
  error,
  exercise,
  footer,
  headline,
  modal,
  navigation,
  select,
  toast,
  word,
  metaTitle,
  metaDescription,
  imprint,
  placeholder,
  label,
  pages: {
    maximalkraftrechner,
    kraftwerteRechner,
    pulsRechner,
    tutorials,
    wiki,
  },
};

const i18n = {
  translations: {
    de: deTranslations,
  },
  defaultLang: 'de',
};

module.exports = i18n;
