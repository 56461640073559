import clsx from 'clsx';
import { useState } from 'react';
import DatePicker from 'react-datepicker';

export function FormDatepicker(props: any) {
  const {
    date = new Date(),
    label = '',
    onSelect,
    containerClassName = '',
    hasErrors = false,
    showTimeSelect = false,
  } = props;
  const [startDate, setStartDate] = useState(date);

  const onChange = (newDate: Date | null) => {
    setStartDate(newDate);
    onSelect(newDate);
  };

  return (
    <div
      className={`${containerClassName} ${clsx({
        'is-valid': !hasErrors,
        'is-invalid': hasErrors,
      })}`}
    >
      <label className={`block text-gray-700 text-sm font-bold mb-2`}>{label}</label>
      <DatePicker
        selected={startDate}
        showTimeSelect={showTimeSelect}
        dateFormat="dd.MM.yyyy HH:mm"
        onChange={onChange}
        className="w-full"
      />
    </div>
  );
}
