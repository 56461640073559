import type { DefaultSeoProps } from 'next-seo/lib/types';

export const DEFAULT_SEO_CONFIG = (translate: (key: string) => string, currentPath: string): DefaultSeoProps => ({
  titleTemplate: '%s | Gym-Tracker.de',
  defaultTitle: translate('metaTitle.frontpage'),
  openGraph: {
    type: 'website',
    locale: 'de',
    url: `https://www.gym-tracker.de${currentPath}`,
    site_name: 'Gym Tracker',
  },
});
