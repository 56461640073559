import { createElement } from 'react';

export type HeadlineProps = {
  text: string;
  containerClasses?: string;
  as?: string;
};

export function Headline({ text, containerClasses = '', as = 'h1' }: HeadlineProps) {
  const headline = createElement(as, { className: `font-semi-bold title-font text-gray-900 font-bold text-xl` }, text);

  return (
    <div className={containerClasses}>
      {headline}
      <hr className="h-1 w-20 bg-pink-600 rounded mt-2" />
    </div>
  );
}
