import { BrowserOnlyContent } from '@components/browser-only-content';
import { ROUTES_PATH_CONFIG } from '@config/routes-config';
import { useClient } from '@hooks/client.hook';
import clsx from 'clsx';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-export-i18n';
import { useEffect, useRef, useState } from 'react';
import { AiFillRead, AiOutlineFolderAdd, AiOutlineLogout } from 'react-icons/ai';
import { BiCalculator } from 'react-icons/bi';
import { CgGym } from 'react-icons/cg';
import { FaUserCircle } from 'react-icons/fa';
import { GiProgression, GiTeacher } from 'react-icons/gi';
import { ImProfile } from 'react-icons/im';
import { IoHome } from 'react-icons/io5';
import { RiLoginCircleFill } from 'react-icons/ri';
import { useRecoilValue } from 'recoil';
import { useOnClickOutside } from 'usehooks-ts';

import { userAuthState } from '../state';

import type { ReactElement } from 'react';
import type { IconBaseProps } from 'react-icons';

type NavigationItem = {
  translationKey: string;
  href: () => string;
  // eslint-disable-next-line no-unused-vars
  getIcon: (props: IconBaseProps) => ReactElement;
};

const NAVIGATION_ITEMS: NavigationItem[] = [
  {
    translationKey: 'home',
    href: () => '/',
    getIcon: (props: IconBaseProps) => IoHome(props),
  },
  {
    translationKey: 'tools',
    href: () => '/tools',
    getIcon: (props: IconBaseProps) => BiCalculator(props),
  },
  {
    translationKey: 'tutorials',
    href: () => '/tutorials',
    getIcon: (props: IconBaseProps) => GiTeacher(props),
  },
  {
    translationKey: 'wiki',
    href: () => '/wiki',
    getIcon: (props: IconBaseProps) => AiFillRead(props),
  },
];

const LOGGED_OUT_NAVIGATION_ITEMS: NavigationItem[] = [
  {
    translationKey: 'login',
    href: () => '/login',
    getIcon: (props: IconBaseProps) => RiLoginCircleFill(props),
  },
  {
    translationKey: 'register',
    href: () => '/register',
    getIcon: (props: IconBaseProps) => ImProfile(props),
  },
];

const LOGGED_IN_NAVIGATION_ITEMS: NavigationItem[] = [
  {
    translationKey: 'addSet',
    href: () => '/add-set',
    getIcon: (props: IconBaseProps) => AiOutlineFolderAdd(props),
  },
  {
    translationKey: 'showProgress',
    href: () => '/progress',
    getIcon: (props: IconBaseProps) => GiProgression(props),
  },
  {
    translationKey: 'profile',
    href: () => '/profile',
    getIcon: (props: IconBaseProps) => ImProfile(props),
  },
  {
    translationKey: 'logout',
    href: () => '/logout',
    getIcon: (props: IconBaseProps) => AiOutlineLogout(props),
  },
];

export function Header() {
  const ref = useRef(null);
  const router = useRouter();
  const isLoggedIn = useRecoilValue<boolean>(userAuthState);
  const [isNavigationHidden, setIsNavigationHidden] = useState(true);
  const { t } = useTranslation();
  const isClient = useClient();

  useEffect(() => {
    setIsNavigationHidden(true);
  }, [router.pathname]);

  useOnClickOutside(ref, () => {
    setIsNavigationHidden(true);
  });

  const toggleNavigation = () => {
    setIsNavigationHidden(!isNavigationHidden);
  };

  const isCurrentSection = (navigationItemHref: string) => {
    const isHomeNavigationItem = navigationItemHref === '/';

    return isHomeNavigationItem
      ? router.pathname === navigationItemHref
      : router.pathname.startsWith(navigationItemHref);
  };

  return (
    <nav ref={ref} className="bg-white fixed w-full z-10 top-0 shadow">
      <div className="w-full container mx-auto flex flex-wrap items-center mt-0 pt-3 pb-3 lg:pb-0">
        <div className="w-1/2 pl-2 md:pl-0">
          <Link
            href={ROUTES_PATH_CONFIG.frontpage}
            className="text-gray-900 text-lg md:text-2xl no-underline hover:no-underline font-bold flex items-center"
          >
            <div className="inline-block flex justify-center border border-blue-100 bg-blue-400 rounded mr-3">
              <CgGym className="w-5 h-5 text-white" />
            </div>
            {t('common.siteName')}
          </Link>
        </div>
        <div className="w-1/2 pr-0">
          <BrowserOnlyContent className="flex justify-end">
            {isLoggedIn && (
              <Link href={ROUTES_PATH_CONFIG.profile} className="inline-flex items-center">
                <FaUserCircle className="w-8 h-8 rounded-full" />
              </Link>
            )}
            <div className="block lg:hidden ml-4 mr-4">
              <button
                onClick={() => toggleNavigation()}
                className="flex items-center px-3 py-2 border rounded text-gray-500 border-gray-600 hover:text-gray-900 hover:border-teal-500 appearance-none focus:outline-none"
              >
                <svg className="fill-current h-3 w-3" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                  <title>Menu</title>
                  <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
                </svg>
              </button>
            </div>
          </BrowserOnlyContent>
        </div>

        <div
          className={`w-full flex-grow lg:flex lg:items-center lg:block mt-2 lg:mt-0 bg-white z-20 ${clsx({
            hidden: isNavigationHidden,
          })}`}
        >
          <ul className="list-reset lg:flex flex-1 items-center px-4 md:px-0" suppressHydrationWarning={true}>
            {NAVIGATION_ITEMS.map((item) => (
              <li key={item.translationKey} className="mr-6 my-2 md:my-0">
                <Link
                  href={item.href()}
                  className={`block py-1 md:py-3 pl-1 align-middle no-underline border-b-2 hover:border-orange-600 ${clsx(
                    {
                      'border-orange-600 text-pink-600': isCurrentSection(item.href()),
                      'border-white hover:border-pink-500 text-gray-500 hover:text-gray-900': !isCurrentSection(
                        item.href()
                      ),
                    }
                  )}`}
                >
                  {item.getIcon({ className: 'inline-block w-5 h-5 mr-3 text-pink-600' })}
                  <span className="pb-1 md:pb-0 text-sm">{t(`navigation.${item.translationKey}`)}</span>
                </Link>
              </li>
            ))}

            {isClient &&
              [...(isLoggedIn ? LOGGED_IN_NAVIGATION_ITEMS : LOGGED_OUT_NAVIGATION_ITEMS)].map((item) => (
                <li key={item.translationKey} className="mr-6 my-2 md:my-0">
                  <Link
                    href={item.href()}
                    className={`block py-1 md:py-3 pl-1 align-middle no-underline border-b-2 hover:border-orange-600 ${clsx(
                      {
                        'border-orange-600 text-pink-600': isCurrentSection(item.href()),
                        'border-white hover:border-pink-500 text-gray-500 hover:text-gray-900': !isCurrentSection(
                          item.href()
                        ),
                      }
                    )}`}
                  >
                    {item.getIcon({ className: 'inline-block w-5 h-5 mr-3 text-pink-600' })}
                    <span className="pb-1 md:pb-0 text-sm">{t(`navigation.${item.translationKey}`)}</span>
                  </Link>
                </li>
              ))}
          </ul>
        </div>
      </div>
    </nav>
  );
}
