import '../../styles/index.scss';

import { DEFAULT_SEO_CONFIG } from '@config/default-seo-config';
import de from 'date-fns/locale/de';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-export-i18n';
import { DefaultSeo } from 'next-seo';
import { useEffect } from 'react';
import { setDefaultLocale } from 'react-datepicker';
import { Toaster } from 'react-hot-toast';
import { RecoilRoot } from 'recoil';

import { Content, Footer, Header, LoadingOverlay } from '../components';

import type { AppProps } from 'next/app';

export default function MyApp({ Component, pageProps }: AppProps) {
  const { t } = useTranslation();
  const router = useRouter();

  useEffect(() => {
    setDefaultLocale(de as any);
  }, []);

  return (
    <RecoilRoot>
      <Head>
        <meta name="application-name" content="Gym Tracker" />
        <meta name="format-detection" content="telephone=no" />
        <meta name="mobile-web-app-capable" content="yes" />
        <meta name="theme-color" content="#FFFFFF" />
        <link rel="manifest" href="/manifest.json" />
        <link rel="mask-icon" href="/images/favicons/safari-pinned-tab.svg" color="#60a5fa" />
        <link rel="shortcut icon" href="/favicon.ico" />

        <meta name="apple-mobile-web-app-capable" content="yes" />
        <meta name="apple-mobile-web-app-status-bar-style" content="default" />
        <meta name="apple-mobile-web-app-title" content="Gym Tracker" />
        <link rel="apple-touch-icon-precomposed" sizes="180x180" href="/apple-touch-icon.png" />
        <link rel="apple-touch-icon-precomposed" sizes="152x152" href="/images/favicons/apple-touch-icon-152x152.png" />
        <link rel="apple-touch-icon-precomposed" sizes="144x144" href="/images/favicons/apple-touch-icon-144x144.png" />
        <link rel="apple-touch-icon-precomposed" sizes="120x120" href="/images/favicons/apple-touch-icon-120x120.png" />
        <link rel="apple-touch-icon-precomposed" sizes="114x114" href="/images/favicons/apple-touch-icon-114x114.png" />
        <link rel="apple-touch-icon-precomposed" sizes="76x76" href="/images/favicons/apple-touch-icon-76x76.png" />
        <link rel="apple-touch-icon-precomposed" sizes="72x72" href="/images/favicons/apple-touch-icon-72x72.png" />
        <link rel="apple-touch-icon-precomposed" sizes="60x60" href="/images/favicons/apple-touch-icon-60x60.png" />
        <link rel="apple-touch-icon-precomposed" sizes="57x57" href="/images/favicons/apple-touch-icon-57x57.png" />

        <link rel="icon" type="image/png" href="/images/favicons/favicon-196x196.png" sizes="196x196" />
        <link rel="icon" type="image/png" href="/images/favicons/favicon-128.png" sizes="128x128" />
        <link rel="icon" type="image/png" href="/images/favicons/favicon-96x96.png" sizes="96x96" />
        <link rel="icon" type="image/png" href="/images/favicons/favicon-32x32.png" sizes="32x32" />
        <link rel="icon" type="image/png" href="/images/favicons/favicon-16x16.png" sizes="16x16" />

        <meta name="msapplication-tap-highlight" content="no" />
        <meta name="msapplication-TileColor" content="#60a5fa" />
        <meta name="msapplication-square310x310logo" content="/images/favicons/mstile-310x310.png" />
        <meta name="msapplication-wide310x150logo" content="/images/favicons/mstile-310x150.png" />
        <meta name="msapplication-square150x150logo" content="/images/favicons/mstile-150x150.png" />
        <meta name="msapplication-TileImage" content="/images/favicons/mstile-144x144.png" />
        <meta name="msapplication-square70x70logo" content="/images/favicons/mstile-70x70.png" />
      </Head>
      <Toaster />
      <LoadingOverlay />
      <Header />
      <Content>
        <DefaultSeo {...DEFAULT_SEO_CONFIG(t, router.asPath)} />
        <Component {...pageProps} />
      </Content>
      <Footer />
    </RecoilRoot>
  );
}
